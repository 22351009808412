<template>
    <div class="container">
        <div class="background-image-container">
            <div class="background-image">
                <div class="button-container ">
                    <button class="btn btn-light advertisingButton" @click="addAdvertiseBtnClickHandler">
                        Advertise HERE!
                    </button>
                </div>
            </div>
        </div>
        <br><br><br>
        <channels-list />
        <br>
        <leagues-list />
        <br>
        <div class="row d-flex justify-content-start">
            <h3 class="col-12 title--header m-0">SOME OF OUR SPONSORS AND ADVERTISERS INCLUDE:</h3>
            <div class="col-12 mb-4">
                <primary-advertisers 
                    :primaryAdvertisersAndSponsors="primaryAdvertisersAndSponsors"
                />
                <non-primary-advertisers 
                    :nonPrimaryAdvertisersAndSponsors="limitNonPrimaryAdvertisersAndSponsors"
                />
                <div class="d-flex align-items-center justify-content-center mt-5" v-if="nonPrimaryAdvertisersAndSponsors.length > 8">
                    <h3 class="iq-title text-capitalize justify-content-center heading">
                        <router-link :to="{ name: 'AdvertiseList', params: { ads: totalAdvertisersAndSponsors } }" class="iq-view-all">
                            SEE MORE ADVERTISERS AND SPONSORS
                        </router-link>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

    
<script>
import Channels from "../Components/NLSE/Channel/ChannelList.vue";
import LeagueList from "../Components/NLSE/LeagueList";
import PrimaryAdvertisers from "./PrimaryAdvertisers";
import NonPrimaryAdvertisers from "./NonPrimaryAdvertisers.vue";
import router from "../../../router";
import { fetchPrimaryAdvertisersAndSponsores, fetchNonPrimaryAdvertiserAndSponsors } from "@/services/advertiserAndSponsor";

const getAdvertiserObjectItems = (advertisers) => advertisers.map(({ name, advertiserURL, isPrimarySponsor, image }) => ({ name, advertiserURL, isPrimarySponsor, image }));

export default {
    components: {
        'channels-list': Channels,
        'leagues-list': LeagueList,
        'primary-advertisers': PrimaryAdvertisers,
        'non-primary-advertisers': NonPrimaryAdvertisers,
    },
    data() {
        return {
            primaryAdvertisersAndSponsors: [],
            nonPrimaryAdvertisersAndSponsors: [],
            limitNonPrimaryAdvertisersAndSponsors: [],
            totalAdvertisersAndSponsors: [],
        };
    },
    methods: {
        addAdvertiseBtnClickHandler() {
            router.push('/contact');
        },
    },
    async mounted(){
        const primaryAdvertisersAndSponsors = await fetchPrimaryAdvertisersAndSponsores();
        const nonPrimaryAdvertisersAndSponsors = await fetchNonPrimaryAdvertiserAndSponsors();

        this.primaryAdvertisersAndSponsors = getAdvertiserObjectItems(primaryAdvertisersAndSponsors);
        this.nonPrimaryAdvertisersAndSponsors = getAdvertiserObjectItems(nonPrimaryAdvertisersAndSponsors);

        this.limitNonPrimaryAdvertisersAndSponsors = this.nonPrimaryAdvertisersAndSponsors.slice(0, 8)
        
        this.totalAdvertisersAndSponsors = [
            ...primaryAdvertisersAndSponsors,
            ...nonPrimaryAdvertisersAndSponsors,
        ];
    }
};
</script>
    
<style scoped>
.container {
    position: flex;
    overflow: hidden;
    width: 100%;
}

.background-image-container {
    display: flex;
    position: relative;
}

.background-image {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    background-image: url("../../../assets/images/advertising_GRAY@2x.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}

.advertisingButton {
    position: absolute;
    bottom: 10%;
    left: 25%;
    transform: translateX(-50%);
    z-index: 2;
    font-size: 1.5vw;
    font-weight: bold;
    border-radius: 10px !important;

}

.button-container {
    display: flex;
    justify-content: center;

    align-items: center;
}

/* Mobile styles */
@media (max-width: 768px) {
    .background-image-container {
        text-align: center;
    }

    .button-container {
        display: flex;
        justify-content: center;
        margin-top: 2vh;
        align-items: center;
    }



    .advertisingButton {
        display: absolute;
        background: #FDD700;
        border: #707070;
        opacity: 1;
        bottom: -25%;
        color: #040505;
        cursor: pointer;
        font-size: 2vh;
        border-radius: 10px !important;
        bottom: -20;
        left: 50%;
        width: 95%;
        align-items: center !important;
        text-align: center !important;
        transform: translateX(-50%);
        z-index: 2;
        font-weight: bold;
        transition: bottom 0.3s ease;
    }

}</style>