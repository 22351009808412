import { API, graphqlOperation, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { listPrimaryAdvertiserSponsors } from '@/graphql/queries';
import { getAWSStorageAsset } from '@/services/storage';

/**
 * This methods below helps retrieve the advertisers and sponsors added through the NLSE CMS into the GraphQL Database.
 * The methods below identify the primary advertisers and sponsors from the non-primary advertisers and sponsors.
 */

/**
 * This is a helper method that is used by both methods below to retrieve the advertisers and sponsors based on "isPrimary" status.
 * @param {boolean} isPrimary 
 */
const fetchAdvertisersAndSponsors = async (isPrimary) => {
    try {
        const filter = { isPrimarySponsor: { eq: isPrimary } };
      
        const response = await API.graphql({
            query: listPrimaryAdvertiserSponsors,
            variables: { filter },
            authMode: GRAPHQL_AUTH_MODE.API_KEY
        });

        let advertiserAndSponsorItems = response.data.listPrimaryAdvertiserSponsors.items;

        advertiserAndSponsorItems = await Promise.all(
            advertiserAndSponsorItems.map(async (advertiserAndSponsor) => {
                if(advertiserAndSponsor.imageFilename != " " || advertiserAndSponsor.imageFilename.length > 0 ) {

                try {
                    const imageAsset = await getAWSStorageAsset(`Advertiser/${advertiserAndSponsor.imageFilename[0]}`);
                    advertiserAndSponsor.image = imageAsset;
                    return advertiserAndSponsor;
                }
                catch(error) {
                    console.log(`Error retrieveing image asset: ${error}`);
                }
            }
        })
        );
        return advertiserAndSponsorItems;
    }
    catch(error) {
        console.error(`An error was discovered while attempting to fetch advertisers and sponsors: ${error}`);
    }
};

/**
 * This method returns ONLY the primary advertisers and sponsors
 */
export const fetchPrimaryAdvertisersAndSponsores = async () => {
    return await fetchAdvertisersAndSponsors(true);
};

/**
 * This method returns ONLY the non-primary advertisers and sponsors
 */
export const fetchNonPrimaryAdvertiserAndSponsors = async () => {
    return await fetchAdvertisersAndSponsors(false);
};